import React, { useState , useEffect} from "react";
import Container from "@mui/material/Container";
import { Grid, Box } from "@mui/material";

import backgroundImage from "../../../assets/background.jpeg";
import People from "../../../assets/people.png";
import Palmyra from "../../../assets/Palmyra.png";
import Dama from "../../../assets/dama.png";
import Nature from "../../../assets/nature.png";
import { ToastContainer, toast } from 'react-toastify';
import HeroCard from "../HeroCard/HeroCard";
import axios from "axios";

export default function Hero() {
  
  const [newsData, setNewsData] = useState([]);
  const [newsData2, setNewsData2] = useState([]);
  const [newslist, setNewsList] = useState([]);
  const [article,setArticle]= useState([]);
  useEffect(() => {
    const getNew = () => {
      axios
        .get(
          `https://www.tanaghomtech.com/magazine/public/api/getlatestArticlebycategory/1?include=category,writer,country`
        )
        .then((response) => {
          setNewsData(response?.data.data);
          console.log(response?.data.data);
        
        })
        .catch((error) => {
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
        
        });
    };

    const getNew2 = () => {
     

      axios
        .get(
          `https://www.tanaghomtech.com/magazine/public/api/getlatestArticlebycategory/5?include=category,writer,country`
        )
        .then((response) => {
          setNewsData2(response?.data.data);
          console.log(response?.data.data);
        
        })
        .catch((error) => {
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
        
        });
    };

    getNew();
    getNew2();
  },[]);

  useEffect(() => {
    const getPolitics = () => {
          axios
      .get(
        `https://www.tanaghomtech.com/magazine/public/api/article?include=category,writer,country`
      )
      .then((response) => {
        setNewsList(response?.data.data);
        console.log(response?.data.data);
        
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
        
      });
  };

    getPolitics();
  },[]);
  useEffect(() => {
    const getArticles = () => {
          axios
      .get(
        "https://www.tanaghomtech.com/magazine/public/api/hero?filter[hero_number]=1,2,3,4"

      )
      .then((response) => {
        setArticle(response?.data.data);
        console.log(response?.data.data);
        
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
        
      });
  };

  getArticles();
  },[]);
  
  
  

  
  return (
   
    <>
  

      <Box
       sx={{
         flexGrow: 1,
         marginTop: "1rem",
         height: { sx: "auto", md: "100vh" },
       }}
     >
       <Grid container spacing={1}>
         {/* First card on the left side */}
         <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
           <Box>
             <HeroCard
               title={article[0]?.article_title}
               category="سياسة"
               date={new Date(article[0]?.article_date).toLocaleTimeString("default", {
                 year: "numeric", month: "numeric", day: "numeric", time: "numeric"
               })}
               backgroundImage={"https://www.tanaghomtech.com/magazine/storage/app/public/"+article[0]?.article_image}
               size="91vh"
               categoryColor="#FF5733"  //Example of passing a dynamic color
               link={"/article/"+article[0]?.article_id}
             />
           </Box>
         </Grid>
         <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Grid container spacing={1}>
            {article.slice(1, 4).map((row, index) => (
              <Grid
                key={index}
                item
                xs={index === 2 ? 12 : 6}
                sm={index === 2 ? 12 : 6}
                md={index === 2 ? 12 : 6}
                lg={index === 2 ? 12 : 6}
                xl={index === 2 ? 12 : 6}
                style={{position:"relative"}}
              >
                <HeroCard
                  title={row?.article_title}
                  category={row?.article_category}
                  date={new Date(row?.article_date).toLocaleDateString()}
                  backgroundImage={`https://www.tanaghomtech.com/magazine/storage/app/public/${row?.article_image}`}
                  size={index === 2 ? "45vh" : "45vh"}
                  categoryColor="#FF5733"
                  link={`/article/${row?.id}`}
                />
             
               
              </Grid>
            ))}
          </Grid>
          
        </Grid>
       </Grid>
     </Box>
  
    </>
  );
}
