import React, { useEffect, useState, useRef } from 'react'
import Navbar from '../../Components/Panel/Navbar/Navbar'
import { Button, Grid, Input, Stack } from '@mui/material'
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles for text editor
//import { useArticleStore } from "../../../Pages/Store";
import axios from "axios";
import Swal from "sweetalert2";

// import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Countries from '../Countries/Countries';
import useFetch from '../../hooks/useFetch';
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
const Addarticle = () => {
  const imageRef = useRef();
  const [title, setTitle] = useState("");
  const [brief, setBrief] = useState("");
  const [contentList, setContentList] = useState([]);
  const [writer, setWriter] = useState("");
  const [content, setEditorContent] = useState("");
  const [magazine_number, setMagazine_number] = useState("");
  const [imageapi, setImageapi] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
const [articleid, setArticleid]=useState(null);
const modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' }
    ],
    [{ align: [] }, { color: [] }, { background: [] }], // dropdown with defaults from theme
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  },
  
};

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
  'font','size',
  'bold','italic','underline','strike',
  'color','background',
  'script',
  'header','blockquote','code-block',
  'indent','list',
  'direction','align',
  'link','formula',
]
  //country get
  const [countries] = useFetch("https://www.tanaghomtech.com/magazine/public/api/country");
  const [selectedcountry, setSelectedcountry] = useState("");
  const [selectedcountryid, setSelectedcountryid] = useState("");
  //category get
  const [categories] = useFetch("https://www.tanaghomtech.com/magazine/public/api/category");
  const [selectedcategories, setSelectedcategories] = useState("");
  const [selectedcategoriesid, setSelectedcategoriesid] = useState("");
  //writer get
  const [writers] = useFetch("https://www.tanaghomtech.com/magazine/public/api/writer");
  const [selectedwriterName, setSelectedwriterName] = useState("");
  const [selectedwriterNameid, setSelectedwriterNameid] = useState("");
  //   const [rows, setRows] = useState([]);
  //const setRows = useArticleStore((state) => state.setRows);
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    boxShadow: "none",
  }));
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleBreifChange = (event) => {
    setBrief(event.target.value);
  };

  const handleMagazineNumberChange = (event) => {
    setMagazine_number(event.target.value);
  };

  const handleWriterChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedwriterNameid(selectedValue)
    // Find the selected category name from the original list of categories
    const selectedWriter = writers.find(
      (writer) => writer.id === selectedValue
    );
    if (selectedWriter) {
      setSelectedwriterName(selectedWriter.writerName);
    }
  };
  const addContent = (type) => {
    const newContent = {
      id: contentList.length + 1,
      type: type,
      content: type === "text" ? "" : null
      , // Empty text for text editor, null for image
      preview: null, // Store image preview
    };
    setContentList([...contentList, newContent]);
  };
  const handleTextChange = (id, value) => {
    setContentList((prevContent) =>
      prevContent.map((content) =>
        content.id === id ? { ...content, value: value } : content
      )
    );
  };
  const handleImageUpload = async (id, event) => {
    const file = event.target.files[0];
    await setImageapi(file)
    console.log("file", file);
    console.log("imageapi", imageapi);


    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setContentList((prevContent) =>
          prevContent.map((content) =>
            content.id === id ? { ...content, value: file, preview: reader.result } : content
          )
        );
      };
      reader.readAsDataURL(file);
    }
  };
  const createArticle = async (event,ide) => {
    event.preventDefault();
    console.log(ide);
    
    const token = localStorage.getItem("accessToken"); // Retrieve the token from local storage
    const formData = new FormData();
   if(articleid){
    formData.append("id", articleid)
   }
    formData.append("title", title);
    formData.append("category_id", selectedcountryid);
    formData.append("country_id", selectedcategoriesid);
    formData.append("writer_id", selectedwriterNameid);
    formData.append("brief", brief);
    formData.append("content", 1); // Append the editor's content
    if (contentList.length > 0) {
      contentList.forEach((section, index) => {
        // Append the type of the section.
        formData.append(`sections[${index}][type]`, section.type);
        // Append the content. For text sections this will be the HTML string,
        // and for image sections this will be the File object.
        formData.append(`sections[${index}][content]`, section.value);
      });
    }
    
    formData.append("status",ide)
    formData.append("magazine_number", magazine_number);
    // Append the image file to formData
    formData.append("imageLink", imageapi); // Assuming `image` is the state variable holding the file
    console.log(contentList);

    axios
      .post(
        "https://www.tanaghomtech.com/magazine/public/api/article/saveArticle",
        formData, // Use the formData object here

        {
          headers: {
            Authorization: `Bearer ${token}`,

          },
        }
      )
      .then((response) => {
        console.log(response.data);
setArticleid(response.data.data.id)
        Swal.fire("تم اضافة المقال", "تمت اضافة المقال بشكل صحيح", "success");
      })
      .catch((error) => {
        console.error("There was an error!", error);

        Swal.fire("Error", error.message, "error");
      });
  };
  const handleCountryChange = async (event) => {
    const selectedValue = event.target.value;

    setSelectedcountryid(selectedValue);
    // Find the selected category name from the original list of categories
    const selectedCountry = countries.find(
      (countries) => countries.id === selectedValue
    );
    if (selectedCountry) {
      setSelectedcountry(selectedCountry.countryName);
    }
  };
  const removeContent = (id) => {
    setContentList(contentList.filter((content) => content.id !== id));
  };
  const handleCategoryChange = (event) => {
    const selectedValue = event.target.value;
    console.log("selectedValue",selectedValue);
    
    setSelectedcategoriesid(selectedValue);
    const selectedCategory = categories.find((cat) => cat.id === selectedValue);
    if (selectedCategory) {
      setSelectedcategories(selectedCategory.categoryName);
    }
  };
  const handleFileChange = (event) => {
    if (!event || !event.target || !event.target.files) {
      console.error("Invalid event:", event);
      return;
    }
    const file = event.target.files[0];
    console.log("file2", file);

    if (file) {
      setImageapi(file);
      setPreview(URL.createObjectURL(file)); // Generate image preview URL
    }
  };
  return (

    <div style={{backgroundColor:'#fff'}}>
      <Navbar />
      <Grid container>
        <Grid xs={5} style={{ backgroundColor: "#fff", padding: "30px", height: "100vh", direction: 'rtl' }}  >
          <>
            <Box
              sx={{
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                padding: 2,
              }}
            />
            <Typography variant="h5" align="center">
              اضافة المقال
            </Typography>
            <IconButton
              style={{ position: "absolute", top: "0", right: "0" }}

            >
              <CloseIcon />
            </IconButton>
            <Box height={20} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="العنوان"
                  variant="outlined"
                  size="small"
                  onChange={handleTitleChange}
                  value={title}
                  sx={{ minWidth: "100%" }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-select-small-label">الفئة</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={selectedcategoriesid}
                    label="الفئة"
                    onChange={handleCategoryChange}
                  >
                    {categories &&
                      categories.length > 0 &&
                      categories.map((cat) => (
                        <MenuItem key={cat.id} value={cat.id}>
                          {cat.categoryName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-select-small-label">الكاتب</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={selectedwriterNameid}
                    label="الكاتب"
                    onChange={handleWriterChange}
                  >
                    {writers &&
                      writers.length > 0 &&
                      writers.map((writer) => (
                        <MenuItem key={writer.id} value={writer.id}>
                          {writer.writerName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth size="small">

                  <InputLabel id="demo-select-small-label">الدولة</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={selectedcountryid}
                    label="الدولة"
                    onChange={handleCountryChange}
                  >
                    {countries &&
                      countries.length > 0 &&
                      countries.map((country) => (
                        <MenuItem key={country.id} value={country.id}>
                          {country.countryName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="الموجز"
                  variant="outlined"
                  size="small"
                  onChange={handleBreifChange}
                  value={brief}
                  sx={{ minWidth: "100%" }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="رقم العدد"
                  variant="outlined"
                  size="small"
                  onChange={handleMagazineNumberChange}
                  value={magazine_number}
                  sx={{ minWidth: "100%" }}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Button variant="contained" component="label">
                  تحميل صورة المقال
                  <input
                    type="file"
                    hidden
                    required
                    fullWidth
                    size="small"
                    accept="image/*"
                    id="select-image"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </Button>
                <div>
                  <Button style={{ marginLeft: "10px" }} variant="contained" onClick={() => addContent("image")}>اضافة صورة</Button>
                  <Button variant="contained" onClick={() => addContent("text")}>اضافة نص</Button>
                </div>
              </Grid>
              {contentList.map((content) => (
                <div key={content.id} style={{ margin: "10px 0", padding: "10px", width: "100%", border: "1px solid #ccc" }}>

                  {content.type === "image" ? (
                    <>
                      <button
                        onClick={() => removeContent(content.id)}

                      >
                        ❌
                      </button>
                      <p>Upload Image:</p>
                      <input type="file" accept="image/*" onChange={(e) => handleImageUpload(content.id, e)} />
                      {content.content && <p>Selected File: {content.value.name}</p>}
                    </>
                  ) : (
                    <> <button
                      onClick={() => removeContent(content.id)}

                    >
                      ❌
                    </button>
                      <p>Text Editor:</p>
                      <button
                        onClick={() => removeContent(content.id)}
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                          background: "red",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          width: "25px",
                          height: "25px",
                          cursor: "pointer",
                        }}
                      >
                        ❌
                      </button>
                      <ReactQuill style={{direction:"rtl"}} value={content.value} modules={modules} formats={formats} onChange={(value) => handleTextChange(content.id, value)} />
                    </>
                  )}
                </div>
              ))}
 <Grid item xs={12}>
                <Typography variant="h5" align="center">
                  <Button style={{ width: "100%" }} variant="outlined" onClick={(e)=>createArticle(e,0)}>
                    حفظ كمسودة
                  </Button>
                </Typography>
              </Grid>
           
              <Grid item xs={12}>
                <Typography variant="h5" align="center">
                  <Button style={{ width: "100%" }} variant="outlined" onClick={(e)=>createArticle(e,1)}>
                    اضافة
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </>
        </Grid>
        <Grid xs={6} style={{ backgroundColor: "#fff", padding: "50px" }}>
          <Stack spacing={2}>
            <Item
              sx={{
                fontSize: "32px",
                fontWeight: 800,
                textAlign: "right",
                color: "#000",
                boxShadow: "none",
                paddingTop: "50px"
              }}
            >
              {title}
            </Item>
            <Item
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                textAlign: "right",
                color: "#000",
                boxShadow: "none",
              }}
            >
              {selectedwriterName} | {selectedcategories} |  {selectedcountry}
            </Item>
            <Item
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                textAlign: "right",
                color: "#000",
                boxShadow: "none",
              }}
            >
              <img
                src={preview}
                alt="User"
                style={{ width: "100%", maxHeight: '600px' }}
              />
            </Item>
            <Item
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                textAlign: "right",
                color: "#000",
                boxShadow: "none",
                direction: 'rtl'
              }}
            >
              {contentList.length === 0 ? <p>No content added</p> :
                contentList.map((content) => (
                  <div key={content.id} style={{ marginBottom: "10px" }}>
                    {content.type === "image" ? (
                      <>
                        {content.preview && <img src={content.preview} alt="Preview" style={{ width: "100%", maxHeight: '600px' }} />}
                      </>
                    ) : (
                      <>
                        <div dangerouslySetInnerHTML={{ __html: content.value }} style={{ padding: "5px" }} />
                      </>
                    )}
                  </div>
                ))
              }
            </Item>
          </Stack>
        </Grid>
      </Grid>
    </div>
  )
}

export default Addarticle
