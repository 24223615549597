import axios from "axios";
import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";

const Form = ({ onSkip }) => {
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    phone: "",
    email: "",
    notes: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const checkEmailURL = `https://www.tanaghomtech.com/magazine/public/api/member/checkemail?email=${formData.email}`;
      const res = await axios.get(checkEmailURL);

      if (res.data.data === "ok") {
        const data = new FormData();
        Object.keys(formData).forEach((key) => data.append(key, formData[key]));

        await axios.post("https://www.tanaghomtech.com/magazine/public/api/member", data);
        toast.success("تم ارسال الطلب");
        setFormData({ name: "", company: "", phone: "", email: "", notes: "" });
      } else {
        toast.error("هذا الايميل موجود مسبقا");
      }
    } catch (error) {
      toast.error("حدث خطأ، يرجى إعادة المحاولة");
    }
  };


    const handleSkip = (e) => {
      e.preventDefault(); // Prevent default link behavior
      localStorage.setItem("skip", "true"); // Mark form as skipped
      onSkip(); // Update state in Home.js
  };

  return (
    <section className="get-in-touch">
      <ToastContainer />
      <h1 className="title">مؤتمر القوى الوطنية الديموقراطية</h1>
      <h4 className="title2">طلب انضمام</h4>
      <form className="contact-form row" onSubmit={handleSubmit}>
        {["name", "company", "phone", "email", "notes"].map((field) => (
          <div key={field} className={`form-field col-lg-${field === "notes" ? "12" : "6"}`}>
            <input
              id={field}
              className="input-text js-input"
              name={field}
              value={formData[field]}
              onChange={handleChange}
              type={field === "email" ? "email" : "text"}
              required={["name", "phone", "email"].includes(field)}
            />
            <label className="label" htmlFor={field}>
              {field === "name" ? "الاسم" : field === "company" ? "اسم الجهة" : field === "phone" ? "رقم الجوال" : field === "email" ? "الايميل" : "معلومات اخرى"}
              {["name", "phone", "email"].includes(field) && <span style={{ color: "red" }}>*</span>}
            </label>
          </div>
        ))}
        <div className="col-lg-12" style={{display:"flex", alignItems:"center",justifyContent:"space-between"}}>
          <button type="submit" className="button-82-pushable p-4">
            <span className="button-82-shadow"></span>
            <span className="button-82-edge"></span>
            <span className="button-82-front text">ارسال</span>
          </button>
          <a href="/" onClick={handleSkip} style={{color:"gray"}}>الانتقال الى موقع تجمع مستقلون
          </a>
        </div>
      </form>
    </section>
  );
};

export default Form
