import React, { useEffect, useState } from "react";
import Navbar1 from "../../Components/Home/Navbar/Navbar1";
import Navbar2 from "../../Components/Home/Navbar/Navbar2";
import Footer from "../../Components/Home/Footer/Footer";
import Container from "@mui/material/Container";
import Hero from "../../Components/Home/Hero/Hero";
import Topbar from "../../Components/Home/TopBar/Topbar";
import Economy from "../../Components/Home/Economy/Economy";
import Politics from "../../Components/Home/Politics/Politics";
import Religion from "../../Components/Home/Religion and life/Religion";
import Rights from "../../Components/Home/rights/Rights";
import Data from "../../Components/Home/Data/Data";
import TheoreticalTrends from "../../Components/Home/Theoretical trends/TheoreticalTrends";
import Education from "../../Components/Home/Education/Education";
import Interview from "../../Components/Home/Interviews/Interview";
import Openion from "../../Components/Home/Openion/Openion";
import Multimedia from "../../Components/Home/Multimedia/Multimedia";
import Form from "../../Components/Home/Form/Form";

const Home = () => {
  const [skip, setSkip] = useState(false);

  // Always show form on refresh by clearing localStorage
  useEffect(() => {
    localStorage.removeItem("skip"); // Reset the value on page load
    setSkip(false);
  }, []);

  const handleSkip = () => {
    localStorage.setItem("skip", "true"); // Store user preference
    setSkip(true); // Hide the form after clicking "انتقال الى الموقع"
  };

  return (
    <Container maxWidth="xl">
      {!skip ? (
        <Form onSkip={handleSkip} /> 
      ) : (
        <>
        
          <Navbar2 />
          <Hero />
          <Politics />
          <Economy />
          <Education />
          <Religion />
          <Openion />
          <TheoreticalTrends />
          <Data />
          <Interview />
          <Multimedia />
          <Rights />
          <Footer />
        </>
      )}
    </Container>
  );
};

export default Home;
