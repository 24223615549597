import { Box, Grid, TextField } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
const Search = () => {
    const[searchTerm, setSearchTerm] =React.useState();
    const navigate = useNavigate();
      const handleChange =(target) =>{
       setSearchTerm(target)
        console.log(target)
      }
      const handleClick =async()=>{
        navigate(`/subsearch?search=${searchTerm}`)}
  return (
    <Grid xs={3} style={{margin:"10px 0px 30px 50px"}}>
    <span>
    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
    <TextField id="input-with-sx" label="بحث" variant="standard" value={searchTerm} onChange={(e)=>handleChange(e.target.value)} />
          <SearchIcon onClick={handleClick} sx={{ color: 'action.active', mr: 1, my: 0.5 }} />

  </Box>
    </span>    
  </Grid>
  )
}

export default Search
